
.reportGridWrapper {
  overflow: auto;
  width: 100%;
}
.reportGridWrapper>div {
  width: 99%;
  overflow: auto;
}

table#reportGrid {
  background: #fff;
  width: 100% !important;
  font-size: 12px;
}

.dataTables_scrollHead th {
  font-size: 12px;
  color: #fff;
  text-align: left;
}
.dataTables_scrollBody {
  font-size: 12px;
}
.dataTables_scrollHead th:before,
.dataTables_scrollHead th:after {
  top: 0px;
}


table.dataTable.no-footer {
  margin-top: 0px !important;
  padding: 4px 0px;
}

table#reportGrid tbody td {
  border: 1px solid #a9a9a9;
  border-top: 0;
  border-right: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
}


div#reportGrid ul > li {
  padding: 0px 5px;
  font-size: 12px;
}
div#reportGrid ul > li a {
  text-decoration: none;
}
div#reportGrid ul > li.disabled a {
  color: #9d9d9d !important;
}
div#reportGrid ul > li.active {
  font-weight: 500;
}

table#reportGrid thead th {
  border: 1px solid #c0c0c0;
  border-right: none;
  background: #08283d;
  color: #fff;
}
table#reportGrid thead th:last-child {
  border-right: 1px solid #c0c0c0;
}

table#reportGrid tbody td:last-child {
  border-right: 1px solid #c0c0c0;
}


table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after {
  top: 2px;
}

.dataTables_scrollHead {
  background: var(--secondaryColor);
}

div#reportGrid_wrapper thead th {
  color: #fff;
  white-space: nowrap;
  border-right: 1px solid #6b6b7b;
}

.reportFilters .autocomplete-box input {
  font-size: 12px;
  top: 2px;
  position: relative;
}

.submenu .report-menu {
  padding-left: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.submenu .report-menu > div {
  min-width: auto;
}

.submenu .report-menu > div svg {
  padding-right: 5px;
  font-size: 20px;
}

.submenu {
  margin-left: 38px !important;
}

.dashboardFilterMenu {
  padding: 0 !important;
}

.dashboardFilterMenu > div {
  padding: 0px 10px;
}

.dashboardFilterMenu span {
  font-size: 12px;
}

.analysis .subtitle {
  margin-bottom: 10px;
}

div#dashboardStats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

div#dashboardStats .statsBox {
  box-shadow: 1px 2px 10px #dbdbdb;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #c6c6c6;
  margin-bottom: 30px;
  padding: 0px 10px;
}

div#dashboardStats .statsBox .statsFilter {
  display: flex;
  justify-content: flex-end;
}

div#dashboardStats .statsBox .statsFilter .dropdown {
  width: 200px;
}

.menuicon svg.svg-inline--fa {
  font-size: 16px;
}

.chartFilter {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 20%;
  right: 15px;
  top: 15px;
  z-index: 999;
}

.chartFilter button {
  background: #fff;
  color: #000;
  margin-bottom: 5px;
  min-width: auto;
  padding: 2px 8px;
  box-shadow: none;
  z-index: 9999;
  margin-left: 5px;
}

.chartFilter button:hover {
  background: #fff;
}

.chartFilter button svg {
  font-size: 18px;
}

.chartFilter p {
  position: absolute;
  z-index: 99;
  left: -1px;
  top: 5px;
  font-size: 12px;
  color: #353549b0;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 0;
}

.graphMenuPopover nav > div.active span {
  font-weight: 500;
}

#reportPageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

div#reportPageContainer div#reportDashLeft {
  width: 74%;
}
div#reportDashLeft .subtitle {
  margin-top: 15px;
  margin-bottom: 0;
  color: var(--secondaryColor);
  font-size: 14px;
}

div#reportPageContainer #reportDashRight {
  width: 25%;
}

div#reportPageContainer .reportDashCard {
  border: 1px solid #dfdbdb;
  border-radius: 4px;
  padding: 0px 15px;
  margin-bottom: 15px;
  background: #fff;
  position: relative;
}

.reportCardHeader h4 {
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
}

.reportCardHeader span {
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
}
.reportCardList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 5px 0px;
  transition: all 0.1s;
}
.reportCardList > span.reportCardListLeft {
  background: #efeff9;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.reportCardList > span.reportCardListMid {
  width: calc(70% - 25px);
  font-size: 14px;
  color: var(--primaryColor);
  font-weight: 500;
}
.reportCardList > span.reportCardListRight {
  width: 30%;
  font-size: 16px;
  color: var(--secondaryColor);
  font-weight: 500;
  text-align: center;
  position: relative;
  top: 1px;
}
.reportDashSmallGraphs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
.reportDashSmallGraphs > div {
  width: 28%;
}

span.stopped {
  color: red;
}
span.running {
  color: green;
}

.reportCardList.cursor:hover span {
  font-weight: bold;
  cursor: pointer;
}
.reportCardBody > div.cursor:hover > span.reportCardListLeft {
  background: var(--primaryColor);
}



div#reportGrid_info {
  font-size: 12px;
}
div#reportGrid_paginate {
  width: 65%;
  position: relative;
  top: -15px;
  margin-right: 0px;
  margin-left: auto;
}
.reportGridWrapper ul.pagination {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px !important;
  font-size: 12px;
}
.reportGridWrapper ul.pagination>li {
  padding-right: 10px;
}
.reportGridWrapper ul.pagination>li.active {
  font-weight: bold;
}
.reportGridWrapper ul.pagination>li.disabled a {
  color: #8b8b8b !IMPORTANT;
}
.reportGridWrapper div#reportGrid_filter {
  width: 50%;
  margin-right: 0px;
  margin-left: auto;
  margin-top: -20px;
}
.reportGridWrapper .dt-buttons.btn-group.flex-wrap {
  width: 50%;
  margin-left: 0px;
  margin-right: auto;
}

