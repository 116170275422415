.loginPage {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: url(../../../assets/images/login-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.loginBox {
    filter: drop-shadow(1px 2px 5px #5a5a5a);
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    width: 350px;
}
.viewPassword {
    position: absolute !important;
    right: 2px;
    top: 25px;
    padding: 5px !important;
}
.viewPassword svg{
    font-size: 18px;
}
.loginBox>img {
    width: 80% !important;
    margin: 10px auto !important;
    display: block;
    margin-bottom: 5px !important;
}